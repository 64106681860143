import React, { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser";
import { b2cPolicies, loginRequest } from "./msalAuthConfig";
import './App.scss';
import { getCurrentUser } from "./ducks/user";
import { useDispatch, useSelector } from "react-redux";
import { acquireAccessToken } from './services/api/auth'
import { AppLoadContext } from './components/ui/AppLoadContext'
import { apiInstance } from './services/apiInstance'
import { msalInstance } from "./index";
import SessionTimeout from "./components/SessionTimeout";
import { getPowerBiEmbededUrls } from "./ducks/report";
import * as SessionHandler from "./services/sessionHandler";
import Pages from "./views/Pages";
import { getStarPlannerShinyURL } from "./ducks/quality-management";
import Loader from "./components/ui/Loader";
import AlertModal from "./components/ui/AlertModal";
import { AlertContext } from "./context/AlertContext";
import { SubscriptionContext } from "./context/SubscriptionContext";
import { getCompanySubscriptions } from './ducks/company';
const LOGOUT_TIMEOUT_TIMER = 60000; // 60 seconds

function App() {
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const [isAppLoaded, setIsAppLoaded] = useState(false);
    const [appSubscription, setAppSubscription] = useState(null);
    const [alert, setAlert] = useState({ show: false, message: '', type: null });

    const currentUser = useSelector((state) => state.user.currentUser);
    const { subscriptions } = useSelector(state => state.company);

    let fetchData = React.useCallback(async () => {
        setIsAppLoaded(false);
        const accessToken = await acquireAccessToken(msalInstance);
        if (accessToken) {
            setIsAppLoaded(true);
            apiInstance.setAuth(accessToken);

            dispatch(getPowerBiEmbededUrls());
            dispatch(getStarPlannerShinyURL());

            dispatch(getCurrentUser(instance, async () => {
                return instance.logoutRedirect({
                    postLogoutRedirectUri: `${window.location.origin}/error-redirect`,
                });
            }));
        }
    }, [dispatch]);

    const handleShowAlert = (message, type) => {
        setAlert({ show: true, message: message, type: type });
    }

    const handleHideAlert = () => {
        setAlert({ ...alert, show: false });
    }

    const handleExitAlert = () => {
        setAlert({ ...alert, message: '', type: null });
    }

    useEffect(() => {
        apiInstance.setContext(handleShowAlert);
    }, [])

    useEffect(() => {
        fetchData();
    }, [fetchData])

    useEffect(() => {
        if (isAppLoaded && !currentUser) {
            const timer = setTimeout(async () => {
                await instance.logoutRedirect({
                    postLogoutRedirectUri: `${window.location.origin}/error-redirect`,
                });
            }, LOGOUT_TIMEOUT_TIMER);
            return () => clearTimeout(timer);
        }

        if (isAppLoaded && currentUser) {
            dispatch(getCompanySubscriptions(currentUser?.companyId));
        }
    }, [isAppLoaded, currentUser]);

    useEffect(() => {
        if (subscriptions) {
            setAppSubscription(subscriptions);
        }
    }, [subscriptions]);

    useEffect(() => {
        const callbackId = instance.addEventCallback(async (event) => {

            if (event.eventType === EventType.LOGIN_FAILURE) {
                if (event.error && event.error.errorMessage.indexOf("AADB2C90118") > -1) {
                    if (event.interactionType === InteractionType.Redirect) {
                        instance.loginRedirect(b2cPolicies.authorities.forgotPassword);
                    } else if (event.interactionType === InteractionType.Popup) {
                        instance.loginPopup(b2cPolicies.authorities.forgotPassword)
                            .catch(() => {
                                return;
                            });
                    }
                }
            }

            if (event.eventType === EventType.LOGIN_SUCCESS) {
                console.log('post login');

                dispatch(getCurrentUser(instance, async () => {
                    return instance.logoutRedirect({
                        postLogoutRedirectUri: `${window.location.origin}/error-redirect`,
                    });
                }));

                fetchData();

            } else if (event.eventType === EventType.ACQUIRE_TOKEN_FAILURE) {
                await instance.loginRedirect(loginRequest);
            }

            if (event.eventType === EventType.LOGOUT_SUCCESS) {
                console.log('post logout')
                dispatch({ type: 'CLEAR_STORE' });
                return;
            }

            if (event.eventType === EventType.LOGIN_SUCCESS || event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
                if (event?.payload) {

                    // listen for any new sessions
                    let userId = event.payload.account?.username;
                    let accessToken = event.payload.accessToken;
                    let timestamp = event.timestamp;

                    await SessionHandler.connect(userId, accessToken, timestamp);

                    /**
                     * We need to reject id tokens that were not issued with the default sign-in policy.
                     * "acr" claim in the token tells us what policy is used (NOTE: for new policies (v2.0), use "tfp" instead of "acr").
                     * To learn more about B2C tokens, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/tokens-overview
                     */
                    if (event.payload.idTokenClaims["acr"] === b2cPolicies.names.forgotPassword) {
                        return instance.logout();
                    }
                }
            }
        });

        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
    }, [dispatch, instance, fetchData]);

    if (isAppLoaded && !currentUser) {
        return (<Loader />);
    }

    return (
        <div className="app">
            <AppLoadContext.Provider value={isAppLoaded}>
                <SubscriptionContext.Provider value={appSubscription}>
                    <AlertContext.Provider value={{ showAlert: handleShowAlert }}>
                        <AlertModal
                            alert={alert}
                            onHide={handleHideAlert}
                            onExited={handleExitAlert}
                        />
                        <SessionTimeout />
                        <Pages />
                    </AlertContext.Provider>
                </SubscriptionContext.Provider>
            </AppLoadContext.Provider>
        </div>
    );
}

export default App;
